<template>
  <teleport to="body">
    <div v-if="open" class="modal">
      <div class="product">
        <div class="content">
          <font-awesome-icon class="exit" icon="fa-circle-xmark" @click="open = !open" />
          <div v-if="!currentAccount.new">
            <h3 class="title">Create Account</h3>
            <p class="line">
              <label class="half">Name</label>
              <input class="half" type="text" v-model="currentAccount.name" placeholder="Name" required />
            </p>
            <p class="line">
              <label class="half">ID</label>
              <input class="half" type="text" v-model="currentAccount.ID" placeholder="ID" required />
            </p>
            <p class="line">
              <label class="half">Balance</label>
              <input class="half" type="number" v-model="currentAccount.value" placeholder="0" />
            </p>
          </div>
          <div v-else>
            <span
              ><h3 class="title">Account: {{ currentAccount.name }}</h3>
            </span>
            <p>ID: {{ currentAccount.ID }}</p>
            <ul class="list">
              <li class="header">
                <span class="half">Date</span>
                <span class="quarter">Type</span>
                <span class="quarter">Value</span>
              </li>

              <li class="transactions" v-for="doc in curAccount" v-bind:key="doc">
                <span class="half">{{ new Date(doc.doc.date).toLocaleDateString() }}</span>
                <span class="quarter">{{ doc.doc.doc_type }}</span>
                <span class="quarter">{{ doc.doc.value }}</span>
              </li>
            </ul>
            <h3>Balance: ${{ currentAccount.value }}</h3>
          </div>
        </div>
        <div class="actions line">
          <div v-if="currentAccount.new" class="half">
            <label>Credit Amount:</label>
            <input class="half" type="number" v-model="creditAmount" placeholder="0" />
          </div>
          <button v-if="currentAccount.new" class="action primary" @click.prevent.stop="credit">Add Credit</button>

          <button v-else class="action primary" @click.prevent.stop="commit">Save</button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountModal",
  props: ["show", "account"],
  emits: ["save", "remove"],
  components: {},
  data() {
    return {
      open: false,
      currentAccount: {},
      creditAmount: 0,
    };
  },
  methods: {
    remove() {
      this.open = !this.open;
      this.$emit("remove", this.currentAccount);
      this.open = false;
    },
    cancel() {
      this.open = !this.open;
    },
    commit() {
      let msg = "";
      const exists = this.accounts.map((account) => {
        return (account.key[1].toString().toLowerCase() === this.currentAccount.name.toLowerCase() || account.key[0].toString().toLowerCase() === this.currentAccount.ID.toLowerCase())
      });
      if (exists.includes(true)) {
        msg = "Account with that Name or ID already exists";
      }else if (!this.currentAccount.name || !this.currentAccount.ID){
        msg = "Please fill out Name and ID";
      }else{
        msg = "Account Saved";
        this.$emit("save", this.currentAccount);
        this.open = false;
      }

      this.$notify({
        text: msg,
        type: msg === "Account Saved" ? "success" : "error",
      });
     

      
      
    },
    credit() {
      this.currentAccount.value = this.creditAmount;
      this.currentAccount.doc_type = "credit";
      this.currentAccount.date = new Date().toISOString();
      console.log(this.creditAmount, this.currentAccount);
      this.$emit("save", this.currentAccount);
      this.open = false;
    },
  },
  watch: {
    show() {
      this.open = true;
    },
    account() {
      this.currentAccount = this.account;
    },
  },
  computed: {
    ...mapGetters({
      curAccount: "curAccount",
      accounts: "accounts",
    }),
  },
};
</script>

<style lang="scss">
@import "public/wrapper";
.modal {
  z-index: 1111;
}

.line {
  display: flex;
  .half {
    flex: 1;
    font-size: large;
    text-align: center;
  }
}

.list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  .header {
    display: flex;
    flex: 1 100%;
  }
  .half {
    flex: 2;
  }
  .quarter {
    flex: 1;
  }
  .transactions {
    display: flex;
    flex: 1 100%;
  }
}
</style>
